import React from "react";
import styled from "styled-components";
import loadingGif from "../../../assets/icons/Logo_animation_Bright.gif";
import loadingGifStart from "../../../assets/icons/logo-animation-bright-start.png";
import { Button as CommonButton } from "../../button/Button.tsx";
import { useChat } from "../../../store/useChat.ts";

export const StyledChatButton = styled(CommonButton)`
  min-width: auto !important;
  width: 2.86rem;
  height: 2.86rem;
  border-radius: var(--leo-radius) !important;
  background: var(--purple-100) !important;

  &[disabled] {
    background-color: var(--dark-50) !important;
    opacity: 0.4;
  }

  img {
    border-radius: var(--leo-radius);
    width: 3.75rem;
    height: 3.75rem;
    object-fit: contain;
  }
`;

interface ButtonProps {
  onClick: () => void;
}

export const ChatButton: React.FC<ButtonProps> = ({ onClick }) => {
  const isLoading = useChat((store) => store.isLoading);

  return (
    <StyledChatButton
      onClick={onClick}
      disabled={isLoading}
      variant="contained"
    >
      {isLoading ? (
        <img src={loadingGif} alt="Loading..." />
      ) : (
        <img src={loadingGifStart} alt="ready" />
      )}
    </StyledChatButton>
  );
};
